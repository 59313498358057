body {
  margin: 0;
  font-family: Helvetica, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: Helvetica;
  src: url('/public/fonts/HelveticaNeueLTPro-Bd_0.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Helvetica;
  src: url('/public/fonts/HelveticaNeueLTPro-Lt_0.otf');
  font-weight: 400;
  font-style: normal;
}

.spin {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
